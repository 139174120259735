import $ from 'jquery'
import 'select2'
import 'select2/dist/css/select2.css'

$(function () {
  $('.select').select2({
    width: '100%',
    minimumResultsForSearch: -1
  });

  if ($('#work_main_category_id').length > 0) {
    $('#work_main_category_id').on('select2:select', function (e) {
    })
  }
  if ($('#step1').length > 0 || $('#step2').length > 0) {
    $('select[required="required"]').on('select2:select', function (e) {
      if ($(this).val()) {
        $(this).closest(".form-item").removeClass("error");
        $(this).closest(".form-item").find(".help-block").remove();
      }
    })
  }
  const $filterWrap = $('.filter-wrap');
  if ($filterWrap.length > 0 && $filterWrap.find('.select').length > 0) {
    const $firstSelect = $('#first-select');
    const $secondSelect = $('#second-select');
    const $filterKeyword = $('#filter-keyword');
    const $radios = $('.filter-wrap input[type="radio"]');
    $firstSelect.on('select2:select', function (e) {
        $secondSelect.val('').trigger('change');
        $radios.prop('checked', false);
        $filterKeyword.submit();
        
    });

    $secondSelect.on('select2:select', function (e) {
        $radios.prop('checked', false);
        $filterKeyword.submit();
    });
  }
})
